@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
body {
    color: #000;
    background:#ccc;
    font-family: "Open Sans", sans-serif;
}

  h2 {
    font-size: 8rem;
    color: #472F85;
    letter-spacing: 10px;
    text-transform: uppercase;
    line-height: 1rem;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 2px;
  }
  h3{
    color: #555;
    font-size: 2rem;
    text-transform: uppercase;
    font-family: "Bebas Neue", sans-serif;
  }
  h4 {
    font-size: 1rem;
    font-weight: 400;
  }

  .page {
    font-size: 1.26rem;
    background: #fff;
    width: 934px;
    padding:2rem 0; text-align: left;
  }
  .page p{
    font-size:18px
  }
  .page h1{
    font-size: 2.3rem;
    text-align: center;
    color: #472F85;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .page .strong{
    font-weight: bold;
  }

  .button {
    background: #4537de;
    border-color: #4537de;
    box-shadow: 0 0.1rem 0.3rem #4537de66;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-variation-settings: "wght" 550;
    font-weight: 400;
    padding: 0.75rem 1.5rem;
    text-align: center;
    margin: 10px auto;
    position: fixed;
    right: 2rem;
    top: 2rem;
  }

  .page-break {
    page-break-before: always
  }

  .container {
    flex-direction: column;
    display: flex;
    gap: 16px;
    max-width: 950px;
    width: 100%;
    margin: 0 auto !important;
    align-items: center;
  }

  .logo{
    padding: 20px 0;
    text-align: center;
  }

  .logo svg{
    max-width: 100%;
    width: 440px;
  }
  .first-page{
    padding: 1.5rem;
    background: #fff;
    margin: auto;
    min-height: 100vh;
    text-align: center;
    max-width: 950px;
    width: 100%; text-align: left;
  }
  .first-page h1{
    color: #472F85;
    font-size: 5rem;
    line-height: 1em;
    letter-spacing: 10px;
    margin: 5rem 0 3rem;
    text-transform: uppercase;
    font-family: "Bebas Neue", sans-serif; text-align: left;
  }
  .first-page h3{
    color: #333;
    font-size: 2.0rem;
    letter-spacing: 5px;
    margin: 0 0 30px 0;
    text-transform: uppercase;
    font-family: "Bebas Neue", sans-serif;
  }
.top-bg img{
  width: 100%;
  margin: 10rem 0 0 0;
}
#container {
  padding: 20px;
  box-sizing: border-box;
}

.first-page {
  margin-bottom: 20px;
}

.page {
  page-break-inside: avoid;
  margin-bottom: 20px;
}

.page-break {
  display: block;
  page-break-before: always;
}

.top-bg img {
  max-width: 100%;
  height: auto;
}

h1, h3 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.logo {
  margin-bottom: 20px;
}
