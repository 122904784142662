@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400&family=Philosopher:wght@400;700&display=swap');

/*COLORS*/

$mediumbrown: #784431;
$yellow: #ffc800;
$white: #fff;
$text-color: #333;
$black: #000;
$purple: #6F0CB3;

html {
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.body-background {
  background: url('../images/bg.jpg') no-repeat center center fixed;
  background-size: cover;
}
body {
  font-family: "Lato", serif;
  margin: 0;
 // background: url() no-repeat center;
  background-size: cover;
  color: $text-color;
  // DISABLE HIGHLIGHTING
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  div, p, img, h1, h2, h3, h4, h5, h6{
    max-width: 100% !important;
  }
  p {
    color: #333;
    font-size: 1.05rem;
    line-height: 1.5em;
    margin-bottom: 15px;
  }
  .clearfix{
        clear: both;
  }
  .text-center{
    text-align: center !important;
  }
  h1,
  h2, h3 {
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 auto 0 auto;
    font-weight: 700;
    font-family: 'Philosopher', sans-serif;
    position: relative;
   // overflow: hidden;
    margin-bottom: 1rem;
    color: #333;
    }
    h1{
        color: $white;
        font-size: 4rem;
        line-height: 1.3em;
        span{
            display: block;
            color: $yellow;
            font-size: 2rem;
            line-height: 1.4em;
            letter-spacing: 5px;
            padding: 0 10px;
        }
    }
    h2{
      font-size: 1.8rem;
      margin: 2rem 0 1rem 0;

    }
    h3{
      text-align: left;
      font-size: 1.05rem;
      font-weight: 700;;
      font-family: "Lato", serif;
      margin-top: 20px;
    }
    .sign-desc{
      display: none;
    }
  .container {
    width: 900px;
    margin: auto;
    min-height: 60px;
    position: relative;
    z-index: 2;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  header{
    padding: 1rem;
    text-align: center;
    position: relative;

    &:after{
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
        position: absolute;
        top: 0;
        left: 0;
    }
    .logo{
        margin-bottom: 1rem;
    }
  }

  .main-section{
    background: $white;
    border-radius: 30px;
    border: rgba(0,0,0,0.2) 15px solid;
    color: $white;
    margin: 2rem auto;
  }
  .dawn-message{
    width: 800px;
    margin: 2rem auto;
    background: #fff;
    padding: 1rem;
    border-radius: 20px;
    background: #f5e5bb;
    border: #c29d39 1px double;
    //box-shadow: rgba(0,0,0,0.3) 0 0 10px;
    .dawn-img{
        width: 25%;
        float: left;
        padding: 1rem 5px;
    }
    .dawn-msg{
        width: 70%;
        float: right;
        padding: 5px;

        p{
            font-size: 1.1rem;
            margin-top:2rem;
            line-height: 1.4em;
        }
    }
  }
.title2-border{
  margin: 0px auto 2rem;
  height: 15px;
}
.all-signs{
  margin: 3rem 0 0 0;
}
.sign-nav{
  background: #f5e5bb;
  background: linear-gradient(180deg,  #dab862 0%, #f5e5bb 100%);
  border: #c29d39 1px solid;
  border-radius: 4px;

  ul{
    padding: 0 !important;
    margin: 0 ;
    text-align: center;
    li{
      display: inline-block;
      position: relative;

      a{
        display: block;
        font-size: 1.1rem;
        padding:20px 10px;
        color: #000;
        text-decoration: none;
        &:hover{
          background: linear-gradient(180deg,  #dab862 100%, #f5e5bb 0%);
        }
      }
      a.active{
        background: #413005;
        color: #fff;
      }
    }
  }
}

.dawn-sign{
  margin: 3rem 0 1rem;
}
}/*body end*/


@media (max-width: 1023px) {
  body{
    h1{
      font-size: 2.5rem;
      span{
        font-size: 2rem;
      }
    }
    .container {
      width: 85%;
      margin: auto;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .dawn-message{
      width: 90%;
      margin: 1rem auto;
      .dawn-img{
          width: 40%;
          padding: 1rem 5px;
      }
      .dawn-msg{
          width: 55%;
          padding: 5px;
      }
    }
    .main-section{
      border: rgba(0,0,0,0.2) 10px solid;
      margin: 1rem auto;
    }
  }/*body end*/
}



@media (max-width: 500px) {
  body{
    h1{
      font-size: 1.8rem;

      span{
        font-size: 1.5rem;
      }
    }
    .dawn-message{
      .dawn-img{
        text-align: center;
        width: 100%;
        img{
          width: 250px;
        }
      }
      .dawn-msg{
        width: 100%;
      }
    }
  }/*body end*/
}